<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="8">
        <v-card :loading="loader" class>
          <v-form v-model="valid" ref="form">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Авторизация</v-toolbar-title>
            </v-toolbar>

            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="email"
                    label="Логин/E-mail"
                    prepend-icon="$vuetify.icons.account"
                    :rules="requiredRules"
                    readonly
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="password"
                    prepend-icon="$vuetify.icons.lock"
                    :append-icon="showPassword ? '$vuetify.icons.eye' : '$vuetify.icons.eye_off'"
                    :type="showPassword ? 'text' : 'password'"
                    label="Пароль"
                    hint="Минимум 8 символов"
                    @click:append="showPassword = !showPassword"
                    :rules="passwordRules"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="password_confirmation"
                    prepend-icon="$vuetify.icons.lock_plus"
                    :append-icon="showPassword2 ? '$vuetify.icons.eye' : '$vuetify.icons.eye-off'"
                    :type="showPassword2 ? 'text' : 'password'"
                    label="Повторите пароль"
                    hint="Минимум 8 символов"
                    @click:append="showPassword2 = !showPassword2"
                    :rules="confirmPasswordRules"
                    required
                  ></v-text-field>

                  <!-- <v-checkbox v-model="remember" label="Запомнить"></v-checkbox> -->

                  <v-btn dark color="primary" @click="submit">Сбросить пароль</v-btn>
                </v-col>
           
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    valid: true,
    loading: false,
    showPassword: false,
    showPassword2: false,
    email: "",
    token: "",
    password: "",
    password_confirmation: "",
    requiredRules: [v => !!v || "Поле обязательно"],
    emailRules: [
      v => !!v || "Поле обязательно",
      v => /.+@.+\..+/.test(v) || "Не верно введен E-mail"
    ],
     passwordRules: [
      v => !!v || "Поле обязательно",
      v => (v && v.length >= 8) || "Минимум 8 символов"
    ],
  }),

  methods: {
    validate() {
      return this.$refs.form.validate();
    },

    submit() {
      event.preventDefault();
      if (!this.validate()) return;
      this.loading = true;
      this.$store
        .dispatch("resetPassword", {
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
          token: this.token
        })
        .then(() => {
          this.loading = false;
          // console.log(responce);
          this.$router.push({ name: "Login" });
        })
        .catch(() => {
          this.loading = false;
        });
    },

    clear() {
      this.email = "";
      this.password = "";
    }
  },
  computed: {
    loader() {
      if (this.loading) {
        return "cyan ";
      }
      return false;
    },
    confirmPasswordRules() {
      const rules = [];

      const rule = v =>
        (!!v && v) === this.password || "Пароли должны совпадать";

      rules.push(rule);

      return rules;
    },
  },
  mounted(){
    this.token = this.$route.query.token;
    this.email = this.$route.query.email;
  }
};
</script>
